import * as _lodash2 from "lodash.throttle";

var _lodash = "default" in _lodash2 ? _lodash2.default : _lodash2;

var exports = {};
var throttle = _lodash;

function _emitSocketProgress(uploader, progressData, file) {
  var progress = progressData.progress,
      bytesUploaded = progressData.bytesUploaded,
      bytesTotal = progressData.bytesTotal;

  if (progress) {
    uploader.uppy.log("Upload progress: " + progress);
    uploader.uppy.emit("upload-progress", file, {
      uploader: uploader,
      bytesUploaded: bytesUploaded,
      bytesTotal: bytesTotal
    });
  }
}

exports = throttle(_emitSocketProgress, 300, {
  leading: true,
  trailing: true
});
export default exports;